import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { fuzzySearch } from "./services/fuzzySearch"
import SearchResults from "./SearchResults"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  withResults,
  dataSource,
  onSelect,
  isMobile,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              await setSearchResults(
                fuzzySearch(searchQuery, dataSource.data, dataSource.lookupKeys)
              )
              setIsLoading(false)
            }, 1000)
          )
          break
        default:
          break
      }
    }
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    const PARTNER_REQUEST_STRING = "partner request"
    return (
      (searchResults.length > 0 && searchResults[0].distance === 1) ||
      PARTNER_REQUEST_STRING.includes(searchQuery.toLowerCase())
    )
  }

  return (
    <Fragment>
      <div className="field">
        {!!label && (
          <label
            className={classNames("label", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p
              className={classNames("control has-icons-left", {
                "is-loading": isLoading,
              })}
            >
              <input
                type={type}
                className="input"
                name={name}
                placeholder={placeholder}
                onChange={(event) => {
                  setSearchQuery(event.target.value)
                  // setSelectedResult(null);
                  // if (form) form.handleChange(event);
                }}
                readOnly={isMobile}
              />

              <span className={classNames("icon is-small is-left")}>
                {/* {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : ( */}
                <FontAwesomeIcon icon={faSearch} />
                {/* )} */}
              </span>
            </p>
          </div>
        </div>
      </div>
      <SearchResults
        searchResults={searchResults.slice(0, 50)}
        isExactMatch={handleValidateMatchDistance()}
        onSelect={onSelect}
        setSearchResults={setSearchResults}
      />
    </Fragment>
  )
}

export default Search
